@import "../styles/variables.scss";
body {
  background: #ECECEC;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.form {
  max-width: 500px;
  margin: 0 auto;
}

.notifyForm {
  min-width: 500px;
  margin: 0 auto;
}

.h2 {
  font-weight: 400;
  color: black;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.h2_votes_table {
  font-weight: 400;
  color: black;
  text-align: center;
  padding-top: 10px;

}
.h2_votes_teams_mobile {
  font-weight: 400;
  font-size: 16px;
  color: black;
  text-align: center;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 0px;

    &__img {
      margin-left:3px;
      margin-right:3px;
    }

    &__header {
      width: "100%";
      height: 100;
      border-radius: 4px;
      height: 35px;
      padding-top: 5px;
      margin-top:20px;
      background: $teamSelectionHeader;
    }
}
.h2_votes_teams {
  font-weight: 300;
  font-size:120%;
  color: black;
  text-align: center;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;.h2_votes_teams {
    font-weight: 400;
    font-size:14px;
    color: black;
    text-align: center;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;

      &__img {
        margin-left:10px;
        margin-right:10px;
      }

  }
    &__img {
      margin-left:10px;
      margin-right:10px;
    }

}
/*
span {
  color: red;
} */
// .form {
//   background: #0e101c;
//   max-width: 400px;
//   margin: 0 auto;
// }

// .p {
//   color: #bf1650;
// }

// p::before {
//   display: inline;
//   content: "⚠ ";
// }

.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.textField {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #ffffff;
}

.select {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 14px;
}

.label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 5px;
  margin-top: 20px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.dateLabel {
  text-align: left;
  display: block;
  margin-bottom: 5px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.userInfolabel {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 15px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.typography{
  font-size: 14px !important;
  font-weight: 500 !important;
}

.selectWithLabel {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 14px;
}

.button[type="submit"],
.input[type="submit"] {
  background: blue;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

.button[type="submit"]:hover,
.input[type="submit"]:hover {
  background: blue;
}

.button[type="submit"]:active,
.input[type="button"]:active,
.input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.input:disabled {
  opacity: 0.4;
}

.input[type="button"]:hover {
  transition: 0.3s all;
}

.button[type="submit"],
.input[type="button"],
.input[type="submit"] {
  -webkit-appearance: none;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}
/*
button[type="button"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
} */

.pre {
  color: white;
}

.hr {
  margin-top: 30px;
}

.button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}
.linkStyle {
  margin-left: 1rem;
  margin-top: 0.25rem;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
  text-decoration: none;
  color: 'blue';
};
.success {
  color: green !important;
  font-size: 1rem !important;
  margin-top: 5px;
}
.error {
  color: red;
  font-size: 1rem;
  margin-top: 5px;
}

.divider{
  border-bottom: 2px solid rgb(79, 98, 148);
  margin-Left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-Right: 20px;
}

.link{
  margin-Right: 10px;
}

.tableCellSelected{
  background-color: #fff380;

}

.radioButtons {
  padding-Left: 50px;
  padding-Right: 50px;
}

.radioWithLabel {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 14px;
}

.sortRadioButtons {
  margin-left: 50px;
}
