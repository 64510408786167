@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

$Poppins: 'Poppins', sans-serif;

// colors
$dark: rgb(29,29,29);
$light: #fff;
$primary: rgb(162,162,246);
$bg: rgb(244,244,255);

$teamSelectionHeader: rgb(255,226,187);

$gk: rgb(208,207,207);
$gkLight: rgb(243,243,243);

$def: rgb(216,251,255);
$defLight: rgb(236,253,255);

$mid: rgb(209,242,212);
$midLight: rgb(240,255,241);

$fr: rgb(255,176,176);
$frLight: rgb(255,226,226);

$titleColor: rgba(0,0,0,0.6);

$predictal-purple: rgba(102, 36, 131, 1);
$predictal-red: rgba(230, 49, 43, 1);

$primary-purple: #662483;
$light-purple: #ebd7f4;
$medium-purple: #ce9be4;//#662483;

$primary-red: #e6312b;
$light-red: #f9d4d2;
$medium-red: #ec645f;

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height:
$headerHeight: 80px;

// footer height:
$footerHeight: 60px;
$footerMobileHeight: 50px;

// Registration
$alto: #dedede;
$black: #000000;
$dove-gray: #707070;
$science-blue: #0065be;
$tundora: #444444;
$tundora-2: #464646;
$white: #ffffff;
$font-size-m: 16px;
$font-size-s: 15px;
$font-size-xs: 14px;
$font-size-xxs: 12px;
$font-size-xxxs: 11px;
$font-size-xxxxs: 10px;
$font-size-xxxxxs: 9px;
$font-family-open_sans: "Open Sans", Helvetica;

:export {
  whitecolor: $white;
}
@mixin breakpoint($point) {
    @if $point == md {
        // 768 px
        @media (min-width: 48em){
            @content;
        }
    }

}
